import { Auth } from "aws-amplify";
import { LOCAL_STORAGE, Pages } from "../constants/enums";
import {
  getBookingDateRange,
  setBookingDateRange,
} from "../helpers/localStorage";
import { getDateFormatted } from "../helpers/utils";
import {
  Account,
  Concept,
  Feature,
  ParentConcept,
  UserConcepts,
} from "../models";
import {
  AdminPermissions,
  ConceptListingVariables,
  IBookingsSyncDuration,
  ListingVariables,
  UserConceptsListingVariables,
} from "../models/app";
import useAccount from "./useAccount";
import useAdminGroup from "./useAdminGroup";
import useApp from "./useApp";
import useConcept from "./useConcept";
import useFeature from "./useFeature";
import useParentConcept from "./useParentConcept";
import usePermissions from "./usePermissions";
import useUserConcepts from "./useUserConcepts";

const useAuth = () => {
  const {
    showError,
    showConfirm,
    setSession,
    fetchSession,
    clearSession,
    navigateTo,
    setPermissions,
    setConcepts,
  } = useApp();
  const { getAdminPermissions } = usePermissions();
  const { userConceptsFetch } = useUserConcepts("userConcepts", "userConcept");

  const { accountsFetchByDomainOnline } = useAccount("accounts", "account");
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    "adminGroups",
    "adminGroup"
  );
  const { featuresFetchAll, featuresChangeListingAll } = useFeature(
    "features",
    "feature"
  );
  const { conceptsChangeSelected, conceptsChangeListing, conceptsFetchAll } =
    useConcept("concepts", "concepts");
  const {
    parentconceptsChangeSelected,
    parentconceptsChangeListing,
    parentconceptsFetchAll,
  } = useParentConcept("parentconcepts", "parentconcept");

  async function register(data: any) {
    try {
      await Auth.signUp({
        username: data.email?.toLowerCase(),
        password: data.password,
        attributes: {
          name: data.name?.toLowerCase(),
          email: data.email,
        },
      });

      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error signing up:", err);
    }
  }

  async function login(data: any) {
    const filteredConcepts: Concept[] = [];
    const filteredParentConcepts: ParentConcept[] = [];

    const params: ListingVariables = {
      searchText: "",
      startIndex: 0,
      limit: 1000,
    };

    try {
      const accountsSelected: Account = await accountsFetchByDomainOnline(
        false
      );

      const result = await Promise.all([
        Auth.signIn({
          username: data.email,
          password: data.password,
        }),
      ]);

      const user: any = result[0];

      setSession(user.signInUserSession.idToken.payload);

      const userConceptParams: UserConceptsListingVariables = {
        ...params,
        userID: user.signInUserSession.idToken.payload.sub,
      };

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        userConceptsFetch(userConceptParams),
        conceptsFetchAll(conceptsParams),
        adminGroupsFetchCurrentUser(user.signInUserSession.idToken.payload.sub),
        featuresFetchAll(params),
        parentconceptsFetchAll(conceptsParams),
      ]);

      const userConcepts: UserConcepts = resultData[0][0];
      const allConcepts: Concept[] = resultData[1];
      const userGroup = resultData[2];
      const featuresListingAll: Feature[] = resultData[3];
      const allParentConcepts: ParentConcept[] = resultData[4];

      featuresChangeListingAll(featuresListingAll);

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        for (let parentconcept of allParentConcepts) {
          if (userConcepts.parentConcepts!.includes(parentconcept.id)) {
            filteredParentConcepts.push(parentconcept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(filteredConcepts);
      parentconceptsChangeListing(filteredParentConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT
      );

      const storageParentconcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_PARENT_CONCEPT
      );

      let currentSelectedParentConcept;

      if (
        storageParentconcept &&
        filteredParentConcepts.some((pc) => pc.id === storageParentconcept)
      ) {
        parentconceptsChangeSelected(storageParentconcept);
      } else {
        if (filteredParentConcepts.length > 0) {
          for (var j = 0; j < filteredParentConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.parentConcepts &&
              userConcepts.parentConcepts.includes(filteredParentConcepts[j].id)
            ) {
              currentSelectedParentConcept = filteredParentConcepts[j].id;
              parentconceptsChangeSelected(currentSelectedParentConcept);
              break;
            }
          }
        }
      }

      const storageConceptObj = filteredConcepts.find(
        (c) => c.id === storageConcept
      );

      if (
        storageConcept &&
        filteredConcepts.some((c) => c.id === storageConcept) &&
        storageConceptObj?.parentConceptID === currentSelectedParentConcept
      ) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id) &&
              filteredConcepts[j].parentConceptID ===
                currentSelectedParentConcept
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, "true");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "true");
      localStorage.setItem(
        LOCAL_STORAGE.BOOKING_DATE,
        getDateFormatted(new Date())
      );

      setPermissions(permissions);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }
  async function loginSync() {
    const filteredConcepts: UserConcepts[] = [];

    const params: ListingVariables = {
      searchText: "",
      startIndex: 0,
      limit: 1000,
    };

    try {
      const result = await Promise.all([
        accountsFetchByDomainOnline(true),
        featuresFetchAll(params),
      ]);

      const accountsSelected: Account = result[0];
      const featuresListingAll: Feature[] = result[1];

      const user: any = await fetchSession();
      featuresChangeListingAll(featuresListingAll);

      const userConceptParams: UserConceptsListingVariables = {
        ...params,
        userID: user.sub,
      };

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        userConceptsFetch(userConceptParams),
        conceptsFetchAll(conceptsParams),
        adminGroupsFetchCurrentUser(user.sub),
      ]);

      const userConcepts: UserConcepts = resultData[0][0];
      const allConcepts: Concept[] = resultData[1];
      const userGroup = resultData[2];

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, "true");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "true");
      localStorage.setItem(
        LOCAL_STORAGE.BOOKING_DATE,
        getDateFormatted(new Date())
      );

      setPermissions(permissions);
      setSession(user);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }
  async function logout() {
    try {
      localStorage.clear();

      await Auth.signOut();

      clearSession();
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error signing out: ", err);
    }
  }

  async function forgetPassword(data: any) {
    try {
      await Auth.forgotPassword(data.email);
      showConfirm("Verification code has been sent to your email.");
      navigateTo(`/${Pages.RESET_PASSWORD}`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error sending code to email: ", err);
    }
  }

  async function forgetPasswordSubmit(data: any) {
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log(err);
    }
  }

  return {
    loginSync,
    login,
    register,
    logout,
    forgetPassword,
    forgetPasswordSubmit,
  };
};

export default useAuth;

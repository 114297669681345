import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { OnlineOrderGetOnlineVariables } from "../models/app";
import {
  ModelSortDirection,
  ModelStringKeyConditionInput,
  UpdateOnlineOrderInput,
} from "./../models/GQL_API";
import useApp from "./useApp";
import useConcept from "./useConcept";

import { ModelOnlineOrderFilterInput } from "../API";
import { OrderStatus, TimelineActions } from "../constants/enums";
import { createOnlineOrder, updateOnlineOrder } from "../graphql/mutations";
import {
  getOnlineOrder,
  listOnlineOrders,
  orderByConceptID,
  orderByDeleted,
  orderByStatus,
} from "../graphql/queries";
import { onCreateOnlineOrder } from "../graphql/subscriptions";
import { OnlineOrder } from "../models";
import { CreateOnlineOrderInput } from "../models/GQL_API";
import { HeadCell } from "../models/dataTable";
import confirmOrder from "../services/onlineOrderServices";
import {
  nextAction,
  setFilter,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
  setPreviousToken,
  setSelectedFilters,
} from "../store/ducks/onlineOrders";
import useMenuItem from "./useMenuItem";
import useTimeline from "./useTimeline";

const useOnlineOrder = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const { conceptsGetName } = useConcept("concepts", "concept");
  let nextToken = useSelector((state: any) => state.onlineOrders.nextToken);
  let onlineOrdersListing =
    useSelector((state: any) => state.onlineOrders.listing) ?? [];
  let lastIndex = useSelector((state: any) => state.onlineOrders.lastIndex);
  const storedLimit = useSelector((state: any) => state.onlineOrders.limit);
  const previousTokens = useSelector(
    (state: any) => state.onlineOrders.previousTokens
  );
  let paginationListing = useSelector(
    (state: any) => state.onlineOrders.pagination
  );
  let paginationFilter = useSelector((state: any) => state.onlineOrders.filter);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const userConcepts = useSelector((state: any) => state.app.concepts);
  const { menuItemsUpdate } = useMenuItem("menuItems", "menuItem");
  const { timelinesCreateOnline } = useTimeline("timelines", "timelines");

  function formatDateToYYYYMMDDHHMMSS(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  const session = useSelector((state: any) => state.app.session);

  async function getOnlineByID(params: any) {
    try {
      const { id } = params;
      if (!id) return undefined;
      const onlineOrder: any = await API.graphql({
        query: getOnlineOrder,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return onlineOrder.data.getOnlineOrder;
    } catch (err) {
      throw err;
    }
  }

  async function fetch(params: any) {
    // console.log("fetch");
    try {
      const {
        limit,
        startIndex,
        searchText,
        sort,
        moveForward,
        tableID,
        fromDate,
        toDate,
        statusList,
        conceptID,
        conceptsSelectedFilters,
        // orderStatusesSelectedFilters,
        onlineOrdersSelectedFilters,
      } = params;
      let itemsList: any = null;
      let currentNextToken;
      let requestLimit = limit ? limit : storedLimit;
      let requestPreviousTokens = previousTokens;
      let requestStartIndex = startIndex;
      let listing: any[] = [];

      const filter: any = {
        deleted: { eq: "0" },
        // parentOrder: { eq: "none" },
      };
      filter.or = [];
      filter.and = [];

      if (searchText && searchText.length > 0) {
        filter.or.push({ id: { contains: searchText } });
        filter.or.push({ createdByName: { contains: searchText } });
        filter.or.push({ dispatcherName: { contains: searchText } });
        filter.or.push({ orderType: { contains: searchText } });
        filter.or.push({ orderType: { contains: searchText } });
      }

      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        for (let status of onlineOrdersSelectedFilters) {
          filter.or.push({ status: { eq: status.value } });
        }
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        filter.and.push({
          createdAt: { ge: new Date(fromDate).toISOString() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDatePlusOneDay).toISOString(),
          },
        });
      }

      if (statusList && statusList.length > 0) {
        let or = [];
        for (let status of statusList) {
          or.push({ status: { eq: status } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      if (
        paginationFilter &&
        paginationFilter.toString() !==
          [
            searchText,
            conceptID,
            conceptsSelectedFilters,
            onlineOrdersSelectedFilters,
            fromDate,
            toDate,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setPreviousToken([]));
        dispatch(setLastIndex(0));

        paginationListing = [];
      }
      if (
        lastIndex >= startIndex &&
        lastIndex !== null &&
        paginationListing.length > 0 &&
        selectedConcept === paginationListing[0].conceptID
      ) {
        return paginationListing.slice(startIndex, startIndex + limit);
      }

      // console.log(`filter: ${JSON.stringify(filter)}`);

      itemsList = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        variables: {
          filter,
          // limit: requestLimit ? requestLimit + 1 : 50,
          limit: 100000,
          // startIndex: requestStartIndex ? requestStartIndex : 0,
          nextToken:
            paginationListing.length > 0 &&
            selectedConcept === paginationListing[0].conceptID
              ? nextToken
              : null,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (moveForward) {
        currentNextToken = itemsList?.data?.listOnlineOrders?.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
        dispatch(setLastIndex(startIndex));
        dispatch(setPreviousToken([...previousTokens, nextToken]));
        dispatch(setNextToken(currentNextToken));
      }
      listing = itemsList.data.listOnlineOrders.items;
      if (sort && listing.length > 0) {
        let ordersList = listing.sort((a: any, b: any) => {
          return b.createdAt > a.createdAt ? 1 : -1;
        });
        return ordersList;
      }

      dispatch(setPagination(paginationListing.concat(listing)));
      dispatch(
        setFilter([
          searchText,
          conceptID,
          conceptsSelectedFilters,
          onlineOrdersSelectedFilters,
          fromDate,
          toDate,
        ])
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAll(params: any) {
    // console.log("fetch");
    try {
      const {
        limit,
        startIndex,
        searchText,
        sort,
        moveForward,
        tableID,
        fromDate,
        toDate,
        statusList,
        conceptID,
        conceptsSelectedFilters,
        onlineOrdersSelectedFilters,
      } = params;
      let itemsList: any = null;
      let currentNextToken: string | null = null;
      let requestLimit = limit ? limit : storedLimit;
      let listing: any[] = [];

      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      if (searchText && searchText.length > 0) {
        filter.or.push({ id: { contains: searchText } });
        filter.or.push({ createdByName: { contains: searchText } });
        filter.or.push({ dispatcherName: { contains: searchText } });
        filter.or.push({ orderType: { contains: searchText } });
      }

      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        for (let status of onlineOrdersSelectedFilters) {
          filter.or.push({ status: { eq: status.value } });
        }
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        filter.and.push({
          createdAt: { ge: new Date(fromDate).toISOString() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDatePlusOneDay).toISOString(),
          },
        });
      }

      if (statusList && statusList.length > 0) {
        let or = [];
        for (let status of statusList) {
          or.push({ status: { eq: status } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      do {
        itemsList = await API.graphql<GraphQLQuery<OnlineOrder>>({
          query: listOnlineOrders,
          variables: {
            filter,
            // limit: requestLimit ? requestLimit + 1 : 50,
            limit: 100000,
            nextToken: currentNextToken,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        const items = itemsList.data.listOnlineOrders.items;
        currentNextToken = itemsList.data.listOnlineOrders.nextToken;
        listing = listing.concat(items);
      } while (currentNextToken);

      if (sort && listing.length > 0) {
        listing.sort((a: any, b: any) => {
          return b.createdAt > a.createdAt ? 1 : -1;
        });
      }

      return listing;
    } catch (err) {
      console.log({ err });
      showError(err);
    }
  }

  async function fetchAllOnline(params: any) {
    try {
      const {
        conceptID,
        onlineOrdersSelectedFilters,
        adminsSelectedFilters,
        fromDate,
        toDate,
        limit,
        startIndex,
        acceptedByWaiterID,
        deliveredByWaiterID,
        sort,
        conceptsSelectedFilters,
        preparationAreaID,
        parentOrder,
        tableID,
        userID,
        searchText,
        channel,
      } = params;
      // console.log({ params });
      let requestLimit = limit ? limit : storedLimit;
      let requestStartIndex = startIndex;
      const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: ModelOnlineOrderFilterInput = {};
      filter.and = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }
      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        let or = [];
        for (let statusFilter of onlineOrdersSelectedFilters) {
          or.push({ status: { eq: statusFilter.value } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (searchText && searchText.length > 0) {
        let or = [];
        or.push({ id: { contains: searchText } });
        or.push({ createdByName: { contains: searchText } });
        or.push({ id: { contains: searchText } });
        or.push({ createdByName: { contains: searchText } });
        or.push({ dispatcherName: { contains: searchText } });
        or.push({ orderType: { contains: searchText } });
        or.push({ orderType: { contains: searchText } });
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (adminsSelectedFilters && adminsSelectedFilters.length > 0) {
        const adminFilters = [];
        for (let filter of adminsSelectedFilters) {
          if (filter?.sub) {
            adminFilters.push({
              dispatcherID: { eq: filter.sub },
            });
          }
        }
        if (adminFilters?.length) {
          filter.or = adminFilters;
        }
      }

      if (userID) {
        filter.userID = { eq: userID };
      }
      if (channel) {
        filter.channel = { eq: channel };
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (deliveredByWaiterID || acceptedByWaiterID) {
        let or = [];
        if (deliveredByWaiterID) {
          or.push({ deliveredByWaiterID: { eq: deliveredByWaiterID } });
        }
        if (acceptedByWaiterID) {
          or.push({ acceptedByWaiterID: { eq: acceptedByWaiterID } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (preparationAreaID) {
        filter.preparationAreaID = { eq: preparationAreaID };
      }
      if (parentOrder) {
        filter.parentOrder = { eq: parentOrder };
      }
      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (
        paginationFilter &&
        paginationFilter.toString() !=
          [
            conceptID,
            onlineOrdersSelectedFilters,
            conceptsSelectedFilters,
            adminsSelectedFilters,
            fromDate,
            toDate,
            searchText,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing = [];
        nextToken = null;
        lastIndex = null;
      }
      if (
        lastIndex >= startIndex &&
        lastIndex !== null &&
        startIndex !== 0 &&
        paginationListing.length > 0 &&
        selectedConcept === paginationListing[0].conceptID
      ) {
        return paginationListing.slice(startIndex, startIndex + limit);
      }

      if (filter.and && filter.and.length === 0) {
        delete filter?.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter?.or;
      }

      const variables: any = {
        deleted: "0",
        filter,
        limit: 100000,
        sortDirection: "DESC",
        nextToken:
          paginationListing.length > 0 &&
          startIndex > 0 &&
          selectedConcept === paginationListing[0].conceptID
            ? nextToken
            : null,
      };
      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: orderByDeleted,
        variables,
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const onlineOrderListing = listing.data.orderByDeleted.items;

      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.orderByDeleted.nextToken));
      dispatch(setPagination(paginationListing.concat(onlineOrderListing)));
      dispatch(
        setFilter([
          conceptID,
          onlineOrdersSelectedFilters,
          conceptsSelectedFilters,
          adminsSelectedFilters,
          fromDate,
          toDate,
          searchText,
        ])
      );

      return onlineOrderListing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchMainList(params: any) {
    try {
      const {
        conceptID,
        onlineOrdersSelectedFilters,
        adminsSelectedFilters,
        fromDate,
        toDate,
        limit,
        startIndex,
        acceptedByWaiterID,
        deliveredByWaiterID,
        sort,
        conceptsSelectedFilters,
        preparationAreaID,
        parentOrder,
        tableID,
        userID,
        searchText,
        channel,
      } = params;
      const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: ModelOnlineOrderFilterInput = {};
      filter.and = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }
      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        let or = [];
        for (let statusFilter of onlineOrdersSelectedFilters) {
          or.push({ status: { eq: statusFilter.value } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (searchText && searchText.length > 0) {
        let or = [];
        or.push({ id: { contains: searchText } });
        or.push({ createdByName: { contains: searchText } });
        or.push({ id: { contains: searchText } });
        or.push({ createdByName: { contains: searchText } });
        or.push({ dispatcherName: { contains: searchText } });
        or.push({ orderType: { contains: searchText } });
        or.push({ orderType: { contains: searchText } });
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (adminsSelectedFilters && adminsSelectedFilters.length > 0) {
        const adminFilters = [];
        for (let filter of adminsSelectedFilters) {
          if (filter?.sub) {
            adminFilters.push({
              dispatcherID: { eq: filter.sub },
            });
          }
        }
        if (adminFilters?.length) {
          filter.or = adminFilters;
        }
      }

      if (userID) {
        filter.userID = { eq: userID };
      }
      if (channel) {
        filter.channel = { eq: channel };
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (deliveredByWaiterID || acceptedByWaiterID) {
        let or = [];
        if (deliveredByWaiterID) {
          or.push({ deliveredByWaiterID: { eq: deliveredByWaiterID } });
        }
        if (acceptedByWaiterID) {
          or.push({ acceptedByWaiterID: { eq: acceptedByWaiterID } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (preparationAreaID) {
        filter.preparationAreaID = { eq: preparationAreaID };
      }
      if (parentOrder) {
        filter.parentOrder = { eq: parentOrder };
      }
      if (tableID) {
        filter.tableID = { eq: tableID };
      }
      if (!filter.and.length) {
        delete filter.and;
      }

      const variables: any = {
        deleted: "0",
        filter,
        limit: 100000,
        sortDirection: "DESC",
        nextToken,
      };
      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const ListData: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: orderByDeleted,
        variables,
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = ListData.data.orderByDeleted.nextToken;
      const responseListing = ListData.data.orderByDeleted.items;

      let listing = [...onlineOrdersListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      dispatch(
        setFilter([
          conceptID,
          onlineOrdersSelectedFilters,
          conceptsSelectedFilters,
          adminsSelectedFilters,
          fromDate,
          toDate,
          searchText,
        ])
      );
      return listing;
    } catch (err) {
      showError(err);
      console.error(err);
    }
  }

  async function fetchSpecialOnlineOrders(params: any) {
    try {
      const { limit, channel, conceptID, userID, status } = params;
      if (!userConcepts?.concepts?.length) return [];
      console.log({ params });
      const filter: ModelOnlineOrderFilterInput = {};
      if (conceptID) {
        filter.conceptID = { eq: conceptID };
      } else {
        filter.and = [{ or: [] }];
        for (const userConceptId of userConcepts.concepts) {
          filter.and[0]?.or?.push({
            conceptID: { eq: userConceptId ?? "No Concept" },
          });
        }
      }
      if (userID) {
        filter.userID = { eq: userID };
      }
      if (status && status.length) {
        filter.and = filter.and || [];
        filter.and.push({
          or: status.map((s: string) => ({ status: { eq: s } })),
        });
      }
      if (channel) filter.channel = { eq: channel };
      if (filter.and && filter.and.length === 0) {
        delete filter?.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter?.or;
      }
      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: orderByDeleted,
        variables: {
          filter,
          deleted: "0",
          sortDirection: "DESC",
          limit: 100000,
          nextToken: nextToken,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const newNextToken = listing?.data?.orderByDeleted?.nextToken;
      dispatch(setNextToken(newNextToken));
      dispatch(
        setListing([
          ...onlineOrdersListing,
          ...listing.data.orderByDeleted.items,
        ])
      );
      return [...onlineOrdersListing, ...listing.data.orderByDeleted.items];
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchByStatus(params: any) {
    try {
      const {
        limit,
        status,
        conceptID,
        conceptsSelectedFilters,
        fromDate,
        toDate,
      } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: orderByStatus,
        variables: {
          status: status,
          createdAt: {
            between: [
              new Date(fromDate).toISOString(),
              new Date(toDate).toISOString(),
            ], // "2023-12-28", "2023-12-28"
          },
          filter,
          // limit: limit ?? 1000,
          limit: 100000,
          sortDirection: ModelSortDirection.DESC, // Sort by creation date
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      let orderList = [];

      if (listing.data) {
        orderList = listing.data.orderByStatus.items;
      }

      return orderList;
    } catch (err) {
      console.log(err);
      showError(err);

      return [];
    }
  }

  async function fetchAllByConceptID(params: any) {
    try {
      const { fromDate, toDate, conceptID } = params;
      let listing: any[] = [];
      const createdAtFilter: ModelStringKeyConditionInput = {};

      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        conceptID,
        SortDirection: ModelSortDirection.DESC,
        limit: 100000,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      let nextToken: string | null = null;

      do {
        if (nextToken) {
          variables.nextToken = nextToken;
        }

        const itemsList: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
          query: orderByConceptID,
          variables,
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        const currentPageItems = itemsList?.data?.orderByConceptID.items;
        listing = listing.concat(currentPageItems);
        nextToken = itemsList?.data?.orderByConceptID.nextToken;
      } while (nextToken);

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: OnlineOrderGetOnlineVariables) {
    //do it with index
    try {
      // console.log("getOnline");
      const { userID, conceptID, limit } = params;
      const filter: any = {
        userID: { eq: userID },
        conceptID: { eq: conceptID },
      };
      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        // variables: { filter, limit: limit ?? 1000 },
        variables: { filter, limit: 100000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listOnlineOrders.items;
    } catch (err) {
      showError(err);
    }
  }

  const countItemsQuantity = (orderedItems: any) => {
    let itemCounts: any = {};
    for (let i = 0; i < orderedItems.length; i++) {
      let item = orderedItems[i].menuItemID;
      let quantity = orderedItems[i].quantity;
      if (itemCounts[item]) {
        itemCounts[item] += quantity;
      } else {
        itemCounts[item] = quantity;
      }
    }
    return itemCounts;
  };

  async function updateOnline(params: any) {
    try {
      const {
        orderedItems,
        deliveryFee,
        totalPrice,
        subTaxTotal,
        tax,
        subTotal,
        status,
        orderAddress,
        shift,
        acceptedByWaiterID,
        deliveredByWaiterID,
        acceptedByWaiterName,
        acceptedByWaiterAt,
        sentToKitchenAt,
        acceptedByKitchenAt,
        readyAt,
        outForDeliveryAt,
        deliveredAt,
        children,
        childrenReadyAt,
        agent,
        dispatcherID,
        dispatcherName,
        promoCodeID,
        promoCodeName,
        reason,
      } = params;

      if (orderedItems) {
        for (let i = 0; i < orderedItems.length; i++) {
          delete orderedItems[i].__typename;
        }
      }
      let original = await getOnlineByID(params);
      if (
        status &&
        agent &&
        (status === OrderStatus.acceptedByAgent ||
          status === OrderStatus.cancelled) &&
        original.status === status
      ) {
        showError("order is Processed by another agent");
        return;
      }

      let newChildrenStatus = [...original.childrenStatus];
      let originalReadyAt = original.readyAt;
      let originalOutForDeliveryAt = original.outForDeliveryAt;
      let originalDeliveredAt = original.deliveredAt;
      let originalStatus = original.status;
      if (children) {
        if (children.deliveredAt) {
          children.deliveredAt = new Date(children.deliveredAt).toISOString();
        }
        newChildrenStatus.push(JSON.stringify(children));
        let childrenDelivered = newChildrenStatus.filter((child: any) => {
          return child !== "ready";
        });
        if (childrenDelivered.length === +newChildrenStatus[0] + 1) {
          originalReadyAt = children.readyAt;
          originalOutForDeliveryAt = children.outForDeliveryAt;
          originalDeliveredAt = children.deliveredAt;
          originalStatus = OrderStatus.delivered;
        }
      }
      if (childrenReadyAt) {
        newChildrenStatus.push("ready");
        let childrenReady = newChildrenStatus.filter((child: any) => {
          return child === "ready";
        });
        if (childrenReady.length === +newChildrenStatus[0]) {
          originalStatus = OrderStatus.childrenReady;
          originalReadyAt = new Date(childrenReadyAt).toISOString();
        }
      }

      const updateInput: UpdateOnlineOrderInput = {
        id: original.id,
        status: status ? status : originalStatus,
        dispatcherID: dispatcherID ? dispatcherID : original.dispatcherID,
        dispatcherName: dispatcherName
          ? dispatcherName
          : original.dispatcherName,

        orderAddress: orderAddress ? orderAddress : original.orderAddress,
        shift: shift ? shift : original.shift,
        acceptedByWaiterID: acceptedByWaiterID
          ? acceptedByWaiterID
          : original.acceptedByWaiterID,
        deliveredByWaiterID: deliveredByWaiterID
          ? deliveredByWaiterID
          : original.deliveredByWaiterID,
        acceptedByWaiterName: acceptedByWaiterName
          ? acceptedByWaiterName
          : original.acceptedByWaiterName,
        acceptedByWaiterAt: acceptedByWaiterAt
          ? new Date(acceptedByWaiterAt).toISOString()
          : original.acceptedByWaiterAt,
        sentToKitchenAt: sentToKitchenAt
          ? new Date(sentToKitchenAt).toISOString()
          : original.sentToKitchenAt,
        acceptedByKitchenAt: acceptedByKitchenAt
          ? new Date(acceptedByKitchenAt).toISOString()
          : original.acceptedByKitchenAt,
        readyAt: readyAt ? new Date(readyAt).toISOString() : originalReadyAt,
        outForDeliveryAt: outForDeliveryAt
          ? new Date(outForDeliveryAt).toISOString()
          : originalOutForDeliveryAt,
        deliveredAt: deliveredAt
          ? new Date(deliveredAt).toISOString()
          : originalDeliveredAt,
        childrenStatus: newChildrenStatus,
        reason: reason === "" ? reason : original.reason,
        specialRequest: params.specialRequest
          ? params.specialRequest
          : original.specialRequest,

        totalAmount: totalPrice,
        subTaxTotal: subTaxTotal,
        subTotal: subTotal,
        tax: tax,
        deliveryFee: deliveryFee,
        orderedItems: orderedItems,

        promoCodeID:
          promoCodeID !== undefined ? promoCodeID : original.promoCodeID,
        promoCodeName:
          promoCodeName !== undefined ? promoCodeName : original.promoCodeName,

        _version: original._version,
      };

      // if (orderedItems !== null && orderedItems !== undefined) {
      //   let totalPrice = 0
      //   updateInput.orderedItems = orderedItems

      //   for (let item of orderedItems) {
      //     totalPrice = totalPrice + (item?.price ?? 0) * (item?.quantity ?? 0)
      //   }
      //   /* Calculate VAT */
      //   updateInput.totalAmount = totalPrice * 1.14

      //   /* Add Delivery Fee */
      //   if (deliveryFee) {
      //     updateInput.totalAmount += deliveryFee
      //   }

      //   /* Round up final value */
      //   updateInput.totalAmount = Number(updateInput.totalAmount.toFixed(2))
      // }

      // re check if items are in stock
      let itemCounts = countItemsQuantity(original.orderedItems);
      const isItemsInStock = await menuItemsUpdate({
        data: {
          itemCounts: itemCounts,
          action: "confirm",
        },
      });
      if (!isItemsInStock) return;

      if (
        updateInput.status === OrderStatus.settled ||
        updateInput.status === OrderStatus.deliveredAndSettled
      ) {
        updateInput.paymentStatus = true;
      }

      // Handle the case where promoCodeID is null
      if (updateInput.promoCodeID === null) {
        delete updateInput.promoCodeID;
      }

      const OnlineOrder: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: updateOnlineOrder,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      //   showConfirm(`${singleName} has been update successfully`);
      if (original.status !== OnlineOrder.data.updateOnlineOrder.status) {
        const getActionName = (status: string) => {
          switch (status) {
            case OrderStatus.cancelled:
              return TimelineActions.ORDER_CANCELLED;
            case OrderStatus.updated:
              return TimelineActions.ORDER_UPDATED;
            case OrderStatus.pending:
              return TimelineActions.ORDER_ACCEPTED;
            case OrderStatus.sentToKitchen:
              return TimelineActions.ORDER_SENT_TO_KITCHEN;
            case OrderStatus.deliveredAndSettled:
              return TimelineActions.ORDER_DELIVERED;
            case OrderStatus.outForDelivery:
              return TimelineActions.ORDER_OUR_FOR_DELIVERY;
            default:
              return TimelineActions.ORDER_UPDATED;
          }
        };

        const data = {
          actionName: `${getActionName(
            OnlineOrder.data.updateOnlineOrder.status
          )} (ID: ${original.id.substring(0, 8)})`,
          oldStatus: `Status: ${original.status}`,
          newStatus: `Status: ${OnlineOrder.data.updateOnlineOrder.status}`,
          customerId: OnlineOrder.data.updateOnlineOrder.userID,
          createdByID: session.sub,
          createdByName: session.name,
          transactionId: original.id,
        };
        timelinesCreateOnline(data);
      }
      if (
        OnlineOrder.data.updateOnlineOrder.status !==
          OrderStatus.acceptedByWaiter ||
        OnlineOrder.data.updateOnlineOrder.status !== OrderStatus.outForDelivery
      ) {
        showConfirm(`Order has been processed successfully`);
      }

      if (
        OnlineOrder.data.updateOnlineOrder.status === OrderStatus.settled ||
        OnlineOrder.data.updateOnlineOrder.status ===
          OrderStatus.deliveredAndSettled
      ) {
        const onlineOrderConcept = conceptsListing.filter(
          (concept: any) =>
            concept.id === OnlineOrder.data.updateOnlineOrder.conceptID
        )[0];
        await confirmOrder(
          OnlineOrder.data.updateOnlineOrder,
          onlineOrderConcept
        );
        showConfirm(`Order has been confirmed successfully`);
      }

      if (
        OnlineOrder.data.updateOnlineOrder.status === OrderStatus.sentToKitchen
      ) {
        let itemCounts = countItemsQuantity(
          OnlineOrder.data.updateOnlineOrder.orderedItems
        );

        await Promise.all([
          menuItemsUpdate({
            data: {
              itemCounts: itemCounts,
              orderStatus: OrderStatus.sentToKitchen,
              action: "confirm",
            },
          }),
        ]);
        showConfirm(`Order has been accepted successfully`);
      }

      return OnlineOrder.data.updateOnlineOrder;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function createOnline(params: any, isAuth = true) {
    let {
      userID,
      userName,
      OrderData,
      acceptedByWaiterID,
      deliveredByWaiterID,
      tableID,
      shift,
      tableName,
      childrenOrderData,
      acceptedByWaiterName,
    } = params;
    try {
      if (!OrderData.empSimphId) {
        throw "Invalid Employee ID , Please update it.";
      }
      let input: CreateOnlineOrderInput;
      let status = OrderStatus.pending;
      let concept = conceptsListing.filter(
        (concept: any) => concept.id === OrderData.conceptID
      );
      concept = concept[0];
      // if (
      //   Number(OrderData.totalPrice) >= concept.varifyForAmount ||
      //   (concept.varifyFirstOrder && !OrderData.user.lastOrderDate) ||
      //   (concept.varifyNewAddress &&
      //     JSON.parse(OrderData.orderAddress).firstTime)
      // ) {
      //   status = OrderStatus.review;
      // }
      if (!childrenOrderData) {
        for (let i = 0; i < OrderData.orderedItems.length; i++) {
          delete OrderData.orderedItems[i].__typename;
          if (OrderData.orderedItems[i].index !== undefined) {
            delete OrderData.orderedItems[i].index;
          }
          if (OrderData.orderedItems[i].choiceGroups) {
            delete OrderData.orderedItems[i].choiceGroups;
          }
          if (OrderData.orderedItems[i].choiceGroupsRules) {
            delete OrderData.orderedItems[i].choiceGroupsRules;
          }
          // console.log({ item: OrderData.orderedItems[i] });
        }
        const createInput: CreateOnlineOrderInput = {
          cartID: OrderData.cartID,
          conceptID: OrderData.conceptID,
          userID: OrderData.userID,
          userName: OrderData.name,
          userPhoneNumber: OrderData.phoneNumber,
          orderedItems: OrderData.orderedItems,
          specialRequest: OrderData.specialRequest,
          status,
          paymentStatus: false,
          acceptedByWaiterID: acceptedByWaiterID ? acceptedByWaiterID : null,
          acceptedByWaiterName: acceptedByWaiterName
            ? acceptedByWaiterName
            : null,
          deliveredByWaiterID: deliveredByWaiterID ? deliveredByWaiterID : null,
          tableID: tableID ? tableID : null,
          tableName: tableName ? tableName : null,
          shift: shift ? shift : null,
          orderType: OrderData.orderType,
          orderTime: OrderData.orderTime,
          channel: OrderData.channel,
          paymentType: OrderData.paymentType,
          orderAddress: OrderData.orderAddress,
          totalAmount: OrderData.totalPrice.toFixed(2),
          subTaxTotal: OrderData.subTaxTotal,
          tax: OrderData.tax,
          subTotal: OrderData.subTotal,
          deliveryFee: OrderData.deliveryFee,
          acceptedByWaiterAt: "",
          sentToKitchenAt: "",
          acceptedByKitchenAt: "",
          readyAt: "",
          outForDeliveryAt: "",
          deliveredAt: "",
          createdAt: new Date().toISOString(),
          childrenStatus: [],
          parentOrder: "none",
          preparationAreaID: "",
          createdByID: userID,
          createdByName: userName,
          simphEmpID: OrderData.empSimphId,
        };
        input = createInput;
      } else {
        for (let i = 0; i < OrderData.orderedItems.length; i++) {
          delete OrderData.orderedItems[i].__typename;

          if (OrderData.orderedItems[i].index) {
            delete OrderData.orderedItems[i].index;
          }
          if (OrderData.orderedItems[i].choiceGroups) {
            delete OrderData.orderedItems[i].choiceGroups;
          }
          if (OrderData.orderedItems[i].choiceGroupsRules) {
            delete OrderData.orderedItems[i].choiceGroupsRules;
          }
          // console.log({ OrderData });
        }
        const createInput: CreateOnlineOrderInput = {
          parentOrder: childrenOrderData.parentOrder,
          preparationAreaID: childrenOrderData.preparationAreaID,
          orderedItems: childrenOrderData.orderedItems,
          cartID: childrenOrderData.cartID,
          conceptID: childrenOrderData.conceptID,
          userID: childrenOrderData.userID,
          userName: childrenOrderData.userName,
          userPhoneNumber: childrenOrderData.phoneNumber,
          specialRequest: childrenOrderData.specialRequest,
          status: childrenOrderData.status,
          acceptedByWaiterID: childrenOrderData.acceptedByWaiterID,
          acceptedByWaiterName: childrenOrderData.acceptedByWaiterName,
          deliveredByWaiterID: childrenOrderData.deliveredByWaiterID,
          tableID: childrenOrderData.tableID,
          tableName: childrenOrderData.tableName,
          shift: childrenOrderData.shift,
          orderType: childrenOrderData.orderType,
          orderTime: childrenOrderData.orderTime,
          deliveryFee: childrenOrderData.deliveryFee,
          orderAddress: childrenOrderData.orderAddress,
          totalAmount: childrenOrderData.totalAmount,
          acceptedByWaiterAt: childrenOrderData.acceptedByWaiterAt,
          sentToKitchenAt: new Date(
            childrenOrderData.sentToKitchenAt
          ).toISOString(),
          acceptedByKitchenAt: childrenOrderData.acceptedByKitchenAt,
          readyAt: childrenOrderData.readyAt,
          outForDeliveryAt: childrenOrderData.outForDeliveryAt,
          deliveredAt: childrenOrderData.deliveredAt,
          createdAt: new Date(childrenOrderData.createdAt).toISOString(), // check if it is correct date
          createdByID: childrenOrderData.createdByID,
          createdByName: childrenOrderData.createdByName,
          childrenStatus: [],
          simphEmpID: OrderData.empSimphId,
        };
        input = createInput;
      }
      const OnlineOrder = await API.graphql<
        GraphQLQuery<{ createOnlineOrder: OnlineOrder }>
      >({
        query: createOnlineOrder,
        variables: { input: input },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const data: any = {
        actionName: `${
          TimelineActions.ORDER_CREATED
        } (ID: ${OnlineOrder?.data?.createOnlineOrder?.id?.substring(0, 8)})`,
        oldStatus: `Status: none`,
        newStatus: `Status: ${OnlineOrder?.data?.createOnlineOrder?.status}`,
        customerId: OnlineOrder?.data?.createOnlineOrder.userID,
        createdByID: session.sub,
        createdByName: session.name,
        transactionId: OnlineOrder?.data?.createOnlineOrder.id,
      };

      timelinesCreateOnline(data);
      if (!childrenOrderData) {
        showConfirm(`Order has been created successfully`);
      }

      return OnlineOrder?.data?.createOnlineOrder;
    } catch (err) {
      console.log(err);
      showError(err);
      throw err;
    }
  }

  async function exportAll(params: any) {
    try {
      const data = await fetchAll(params);

      let exportedData = [];

      for (let OnlineOrder of data!) {
        let row: any = { ...OnlineOrder };
        if (OnlineOrder.id) {
          row.id = OnlineOrder.id.split("-")[0];
        }

        if (OnlineOrder.conceptID) {
          row.conceptID = conceptsGetName({
            id: OnlineOrder.conceptID,
            listing: params.conceptsListing,
          });
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      console.log({ err });
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Order Number",
    },
    {
      id: "orderStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "orderType",
      numeric: false,
      disablePadding: false,
      label: "Order Type",
    },
    {
      id: "orderTime",
      numeric: false,
      disablePadding: false,
      label: "Order time",
    },
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User name",
    },
    {
      id: "dispatcherName",
      numeric: false,
      disablePadding: false,
      label: "Dispatcher",
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
    },
    {
      id: "deliveryFee",
      numeric: false,
      disablePadding: false,
      label: "Delivery Fee",
    },
    {
      id: "specialRequest",
      numeric: false,
      disablePadding: false,
      label: "Special request",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created by",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created at",
    },
  ];

  const dataCells: readonly string[] = [
    "conceptID",
    "id",
    "status",
    "reason",
    "orderType",
    "orderTime",
    "userName",
    "dispatcherName",
    "totalAmount",
    "deliveryFee",
    "specialRequest",
    "createdByName",
    "createdAt",
  ];

  const api: any = {};

  api[`${listingName}Model`] = OnlineOrder as any;
  api[`${listingName}CreateSubscription`] = onCreateOnlineOrder;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetOnlineByID`] = getOnlineByID;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAllOnline`] = fetchAllOnline;
  api[`${listingName}FetchSpecial`] = fetchSpecialOnlineOrders;
  api[`${listingName}FetchMainList`] = fetchMainList;
  api[`${listingName}FetchByStatus`] = fetchByStatus;
  api[`${listingName}FetchAllByConceptID`] = fetchAllByConceptID;
  api[`${listingName}ChangeListing`] = (listing: OnlineOrder[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilter(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = onlineOrdersListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useOnlineOrder;

import useAccount from "./useAccount";
import useConcept from "./useConcept";
import useFeature from "./useFeature";
import useUser from "./useUser";
import useTimeline from "./useTimeline";
import useAdminRole from "./useAdminRole";
import useAdminGroup from "./useAdminGroup";
import useUserConcepts from "./useUserConcepts";
import useTransaction from "./useTransaction";
import usePaymentStat from "./usePaymentStat";
import useParentConcepts from "./useParentConcept";
import useMedia from "./useMedia";
import useOnlineOrder from "./useOnlineOrder";
import useMenuItem from "./useMenuItem";
import useCategory from "./useCategory";
import usePrice from "./usePrice";
import useChoice from "./useChoice";
import useChoiceGroup from "./useChoiceGroup";
import useDispatchers from "./useDispatchers";
import useMainCategory from "./useMainCategory";
import useSubCategory from "./useSubCategory";
import useZone from "./useZone";
import useChannel from "./useChannel";
import useCampaign from "./useCampaign";
import useLoyaltyDashboard from "./useLoyaltyDashboard";
import useAdmin from "./useAdmin";
import usePayment from "./usePayment";
import useCategoryTags from "./useCategoryTags";

const useResource = () => {
  const accountsApi = useAccount("accounts", "account");
  const conceptsApi = useConcept("concepts", "concept");
  const featuresApi = useFeature("features", "feature");
  const usersApi = useUser("guests", "guest");
  const timeLinesApi = useTimeline("timelines", "timeline");
  const adminRolesApi = useAdminRole("adminRoles", "adminRole");
  const adminGroupsApi = useAdminGroup("adminGroups", "adminGroup");
  const adminsApi = useAdmin("admins", "admin");
  const userConceptsApi = useUserConcepts("userConcepts", "userConcept");
  const transactionsApi = useTransaction("transactions", "transaction");
  const paymentStatsApi = usePaymentStat("paymentStats", "paymentStat");
  const parentConceptAPI = useParentConcepts("parentconcepts", "parentconcept");
  const mediaLibraryApi = useMedia("mediaLibrary", "media");
  const dispatchersApi = useDispatchers("dispatchers", "dispatcher");
  const mainCategoryApi = useMainCategory("mainCategories", "mainCategory");
  const subCategoryApi = useSubCategory("subCategories", "subCategory");
  const zoneApi = useZone("zones", "zone");
  const channelApi = useChannel("channels", "channel");
  const campaignApi = useCampaign("campaigns", "campaign");
  const loyaltyDashboardApi = useLoyaltyDashboard(
    "loyaltyDashboard",
    "loyaltyDashboards"
  );

  // Online Ordering / Call Center
  const onlineOrderApi = useOnlineOrder("onlineOrders", "onlineOrder");
  const menuItemApi = useMenuItem("menuItems", "menuItem");
  const categoryApi = useCategory("categories", "category");
  const priceApi = usePrice("prices", "price");
  const choiceApi = useChoice("choices", "choice");
  const choiceGroupApi = useChoiceGroup("choiceGroups", "choiceGroup");
  const paymentApi = usePayment("payments", "payment");
  const categoryTagApi = useCategoryTags("categoryTags", "categoryTag");

  return {
    ...adminGroupsApi,
    ...adminsApi,
    ...adminRolesApi,
    ...accountsApi,
    ...conceptsApi,
    ...featuresApi,
    ...usersApi,
    ...timeLinesApi,
    ...userConceptsApi,
    ...transactionsApi,
    ...paymentStatsApi,
    ...parentConceptAPI,
    ...mediaLibraryApi,
    ...dispatchersApi,
    ...mainCategoryApi,
    ...subCategoryApi,
    ...channelApi,
    ...campaignApi,
    ...onlineOrderApi,
    ...menuItemApi,
    ...categoryApi,
    ...priceApi,
    ...choiceApi,
    ...loyaltyDashboardApi,
    ...choiceGroupApi,
    ...zoneApi,
    ...paymentApi,
    ...categoryTagApi,
  };
};

export default useResource;

import { ADMIN_ROLES } from "../constants/enums";
import { AdminPermissions, AdminRoleGetVariables } from "../models/app";
import useAdminRole from "./useAdminRole";

const usePermissions = () => {
  const { adminRolesGet, adminRolesFetchAll } = useAdminRole(
    "adminRoles",
    "adminRole"
  );

  const adminPermissions: AdminPermissions = {
    admins: true,
    dashboard: false,
    settings: false,
    viewReservations: false,
    createReservation: false,
    cancelReservation: false,
    createGuest: false,
    editGuest: false,
    editVip: false,
    lockTable: false,
    editLayout: false,
    transactionReport: false,
    paymentDashboard: false,
    paymobSettings: false,
    callCenter: false,
    waiter: false,
    kitchen: false,
    can_see_payments: false,
    can_see_reports: false,
    can_see_guests: false,
    can_access_order_dashboard: false,
    can_access_warehouse: false,
    can_change_admins_passwords: false,
    can_sync_item_from_simphony: false,
    can_order_later: false,
    can_access_category_tag: false,
  };

  const getAdminPermissions = async (props: {
    userGroup: any;
  }): Promise<AdminPermissions> => {
    const { userGroup } = props;
    if (userGroup && userGroup.length > 0) {
      let userRoles: any[] = [];
      for (let group of userGroup) {
        userRoles = userRoles.concat(group.roles);
      }
      const allRoles = await adminRolesFetchAll({ searchText: "" });
      const userRolesList = allRoles.filter((role: any) =>
        userRoles.includes(role.id)
      );

      for (let role of userRolesList) {
        switch (role.name) {
          case ADMIN_ROLES.ADMINS:
            adminPermissions.admins = true;
            break;
          case ADMIN_ROLES.DASHBOARD:
            adminPermissions.dashboard = true;
            break;
          case ADMIN_ROLES.SETTINGS:
            adminPermissions.settings = true;
            break;
          case ADMIN_ROLES.VIEW_RESERVATIONS:
            adminPermissions.viewReservations = true;
            break;
          case ADMIN_ROLES.CREATE_RESERVATION:
            adminPermissions.createReservation = true;
            break;
          case ADMIN_ROLES.CANCEL_RESERVATION:
            adminPermissions.cancelReservation = true;
            break;
          case ADMIN_ROLES.CREATE_GUEST:
            adminPermissions.createGuest = true;
            break;
          case ADMIN_ROLES.EDIT_GUEST:
            adminPermissions.editGuest = true;
            break;
          case ADMIN_ROLES.EDIT_VIP:
            adminPermissions.editVip = true;
            break;
          case ADMIN_ROLES.EDIT_LAYOUT:
            adminPermissions.editLayout = true;
            break;
          case ADMIN_ROLES.LOCK_TABLE:
            adminPermissions.lockTable = true;
            break;
          case ADMIN_ROLES.TRANSACTION_REPORT:
            adminPermissions.transactionReport = true;
            break;
          case ADMIN_ROLES.PAYMENT_DASHBOARD:
            adminPermissions.paymentDashboard = true;
            break;
          case ADMIN_ROLES.PAYMOB_SETTINGS:
            adminPermissions.paymobSettings = true;
            break;
          case ADMIN_ROLES.CALL_CENTER:
            adminPermissions.callCenter = true;
            break;
          case ADMIN_ROLES.WAITER:
            adminPermissions.waiter = true;
            break;
          case ADMIN_ROLES.KITCHEN:
            adminPermissions.kitchen = true;
            break;
          case ADMIN_ROLES.can_see_payments:
            adminPermissions.can_see_payments = true;
            break;
          case ADMIN_ROLES.can_see_reports:
            adminPermissions.can_see_reports = true;
            break;
          case ADMIN_ROLES.can_see_guests:
            adminPermissions.can_see_guests = true;
            break;
          case ADMIN_ROLES.can_access_order_dashboard:
            adminPermissions.can_access_order_dashboard = true;
            break;
          case ADMIN_ROLES.can_access_warehouse:
            adminPermissions.can_access_warehouse = true;
            break;
          case ADMIN_ROLES.can_change_admins_passwords:
            adminPermissions.can_change_admins_passwords = true;
            break;
          case ADMIN_ROLES.can_sync_item_from_simphony:
            adminPermissions.can_sync_item_from_simphony = true;
            break;
          case ADMIN_ROLES.can_order_later:
            adminPermissions.can_order_later = true;
            break;
          case ADMIN_ROLES.can_access_category_tag:
            adminPermissions.can_access_category_tag = true;
            break;
          default:
            break;
        }
      }
    }

    return adminPermissions;
  };

  return { getAdminPermissions };
};

export default usePermissions;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
    onCreateReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
    onUpdateReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
    onDeleteReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onCreateAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onUpdateAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
    onDeleteAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFeature = /* GraphQL */ `
  subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onCreateFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFeature = /* GraphQL */ `
  subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onUpdateFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFeature = /* GraphQL */ `
  subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onDeleteFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMedia = /* GraphQL */ `
  subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onCreateMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMedia = /* GraphQL */ `
  subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onUpdateMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMedia = /* GraphQL */ `
  subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
    onDeleteMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateConcept = /* GraphQL */ `
  subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onCreateConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateConcept = /* GraphQL */ `
  subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onUpdateConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteConcept = /* GraphQL */ `
  subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
    onDeleteConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCategoryTag = /* GraphQL */ `
  subscription OnCreateCategoryTag(
    $filter: ModelSubscriptionCategoryTagFilterInput
  ) {
    onCreateCategoryTag(filter: $filter) {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCategoryTag = /* GraphQL */ `
  subscription OnUpdateCategoryTag(
    $filter: ModelSubscriptionCategoryTagFilterInput
  ) {
    onUpdateCategoryTag(filter: $filter) {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCategoryTag = /* GraphQL */ `
  subscription OnDeleteCategoryTag(
    $filter: ModelSubscriptionCategoryTagFilterInput
  ) {
    onDeleteCategoryTag(filter: $filter) {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTender = /* GraphQL */ `
  subscription OnCreateTender($filter: ModelSubscriptionTenderFilterInput) {
    onCreateTender(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const onUpdateTender = /* GraphQL */ `
  subscription OnUpdateTender($filter: ModelSubscriptionTenderFilterInput) {
    onUpdateTender(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const onDeleteTender = /* GraphQL */ `
  subscription OnDeleteTender($filter: ModelSubscriptionTenderFilterInput) {
    onDeleteTender(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const onCreateOrderType = /* GraphQL */ `
  subscription OnCreateOrderType(
    $filter: ModelSubscriptionOrderTypeFilterInput
  ) {
    onCreateOrderType(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const onUpdateOrderType = /* GraphQL */ `
  subscription OnUpdateOrderType(
    $filter: ModelSubscriptionOrderTypeFilterInput
  ) {
    onUpdateOrderType(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const onDeleteOrderType = /* GraphQL */ `
  subscription OnDeleteOrderType(
    $filter: ModelSubscriptionOrderTypeFilterInput
  ) {
    onDeleteOrderType(filter: $filter) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const onCreateParentConcept = /* GraphQL */ `
  subscription OnCreateParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onCreateParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            nextToken
            startedAt
            __typename
          }
          orderType {
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateParentConcept = /* GraphQL */ `
  subscription OnUpdateParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onUpdateParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            nextToken
            startedAt
            __typename
          }
          orderType {
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteParentConcept = /* GraphQL */ `
  subscription OnDeleteParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onDeleteParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            nextToken
            startedAt
            __typename
          }
          orderType {
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTimeline = /* GraphQL */ `
  subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onCreateTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTimeline = /* GraphQL */ `
  subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onUpdateTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTimeline = /* GraphQL */ `
  subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onDeleteTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCall = /* GraphQL */ `
  subscription OnCreateCall($filter: ModelSubscriptionCallFilterInput) {
    onCreateCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onUpdateCall = /* GraphQL */ `
  subscription OnUpdateCall($filter: ModelSubscriptionCallFilterInput) {
    onUpdateCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onDeleteCall = /* GraphQL */ `
  subscription OnDeleteCall($filter: ModelSubscriptionCallFilterInput) {
    onDeleteCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onCreateAdminRole = /* GraphQL */ `
  subscription OnCreateAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onCreateAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminRole = /* GraphQL */ `
  subscription OnUpdateAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onUpdateAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminRole = /* GraphQL */ `
  subscription OnDeleteAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onDeleteAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminGroup = /* GraphQL */ `
  subscription OnCreateAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onCreateAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminGroup = /* GraphQL */ `
  subscription OnUpdateAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onUpdateAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminGroup = /* GraphQL */ `
  subscription OnDeleteAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onDeleteAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserConcepts = /* GraphQL */ `
  subscription OnCreateUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onCreateUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserConcepts = /* GraphQL */ `
  subscription OnUpdateUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onUpdateUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserConcepts = /* GraphQL */ `
  subscription OnDeleteUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onDeleteUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onCreateTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onUpdateTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onDeleteTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePaymentStat = /* GraphQL */ `
  subscription OnCreatePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onCreatePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePaymentStat = /* GraphQL */ `
  subscription OnUpdatePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onUpdatePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePaymentStat = /* GraphQL */ `
  subscription OnDeletePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onDeletePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onCreateCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onUpdateCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onDeleteCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMenuItem = /* GraphQL */ `
  subscription OnCreateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onCreateMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMenuItem = /* GraphQL */ `
  subscription OnUpdateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onUpdateMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMenuItem = /* GraphQL */ `
  subscription OnDeleteMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onDeleteMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePrice = /* GraphQL */ `
  subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onCreatePrice(filter: $filter) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePrice = /* GraphQL */ `
  subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onUpdatePrice(filter: $filter) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePrice = /* GraphQL */ `
  subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
    onDeletePrice(filter: $filter) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChoiceGroups = /* GraphQL */ `
  subscription OnCreateChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onCreateChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChoiceGroups = /* GraphQL */ `
  subscription OnUpdateChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onUpdateChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChoiceGroups = /* GraphQL */ `
  subscription OnDeleteChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onDeleteChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChoice = /* GraphQL */ `
  subscription OnCreateChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onCreateChoice(filter: $filter) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChoice = /* GraphQL */ `
  subscription OnUpdateChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onUpdateChoice(filter: $filter) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChoice = /* GraphQL */ `
  subscription OnDeleteChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onDeleteChoice(filter: $filter) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCart = /* GraphQL */ `
  subscription OnCreateCart($filter: ModelSubscriptionCartFilterInput) {
    onCreateCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCart = /* GraphQL */ `
  subscription OnUpdateCart($filter: ModelSubscriptionCartFilterInput) {
    onUpdateCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCart = /* GraphQL */ `
  subscription OnDeleteCart($filter: ModelSubscriptionCartFilterInput) {
    onDeleteCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminStatus = /* GraphQL */ `
  subscription OnCreateAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onCreateAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminStatus = /* GraphQL */ `
  subscription OnUpdateAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onUpdateAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminStatus = /* GraphQL */ `
  subscription OnDeleteAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onDeleteAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOnlineOrder = /* GraphQL */ `
  subscription OnCreateOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onCreateOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOnlineOrder = /* GraphQL */ `
  subscription OnUpdateOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onUpdateOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOnlineOrder = /* GraphQL */ `
  subscription OnDeleteOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onDeleteOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrderStatus = /* GraphQL */ `
  subscription OnCreateOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onCreateOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrderStatus = /* GraphQL */ `
  subscription OnUpdateOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onUpdateOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrderStatus = /* GraphQL */ `
  subscription OnDeleteOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onDeleteOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDiscountCoupon = /* GraphQL */ `
  subscription OnCreateDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onCreateDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDiscountCoupon = /* GraphQL */ `
  subscription OnUpdateDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onUpdateDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDiscountCoupon = /* GraphQL */ `
  subscription OnDeleteDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onDeleteDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateZone = /* GraphQL */ `
  subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
    onCreateZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateZone = /* GraphQL */ `
  subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
    onUpdateZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteZone = /* GraphQL */ `
  subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
    onDeleteZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserIP = /* GraphQL */ `
  subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onCreateUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserIP = /* GraphQL */ `
  subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onUpdateUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserIP = /* GraphQL */ `
  subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onDeleteUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMainCategory = /* GraphQL */ `
  subscription OnCreateMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onCreateMainCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMainCategory = /* GraphQL */ `
  subscription OnUpdateMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onUpdateMainCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMainCategory = /* GraphQL */ `
  subscription OnDeleteMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onDeleteMainCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSubCategory = /* GraphQL */ `
  subscription OnCreateSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onCreateSubCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSubCategory = /* GraphQL */ `
  subscription OnUpdateSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onUpdateSubCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSubCategory = /* GraphQL */ `
  subscription OnDeleteSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onDeleteSubCategory(filter: $filter) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onCreateChannel(filter: $filter) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onUpdateChannel(filter: $filter) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel($filter: ModelSubscriptionChannelFilterInput) {
    onDeleteChannel(filter: $filter) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePromocode = /* GraphQL */ `
  subscription OnCreatePromocode(
    $filter: ModelSubscriptionPromocodeFilterInput
  ) {
    onCreatePromocode(filter: $filter) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePromocode = /* GraphQL */ `
  subscription OnUpdatePromocode(
    $filter: ModelSubscriptionPromocodeFilterInput
  ) {
    onUpdatePromocode(filter: $filter) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePromocode = /* GraphQL */ `
  subscription OnDeletePromocode(
    $filter: ModelSubscriptionPromocodeFilterInput
  ) {
    onDeletePromocode(filter: $filter) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePromocodeDashboard = /* GraphQL */ `
  subscription OnCreatePromocodeDashboard(
    $filter: ModelSubscriptionPromocodeDashboardFilterInput
  ) {
    onCreatePromocodeDashboard(filter: $filter) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePromocodeDashboard = /* GraphQL */ `
  subscription OnUpdatePromocodeDashboard(
    $filter: ModelSubscriptionPromocodeDashboardFilterInput
  ) {
    onUpdatePromocodeDashboard(filter: $filter) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePromocodeDashboard = /* GraphQL */ `
  subscription OnDeletePromocodeDashboard(
    $filter: ModelSubscriptionPromocodeDashboardFilterInput
  ) {
    onDeletePromocodeDashboard(filter: $filter) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLoyaltyItem = /* GraphQL */ `
  subscription OnCreateLoyaltyItem(
    $filter: ModelSubscriptionLoyaltyItemFilterInput
  ) {
    onCreateLoyaltyItem(filter: $filter) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLoyaltyItem = /* GraphQL */ `
  subscription OnUpdateLoyaltyItem(
    $filter: ModelSubscriptionLoyaltyItemFilterInput
  ) {
    onUpdateLoyaltyItem(filter: $filter) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLoyaltyItem = /* GraphQL */ `
  subscription OnDeleteLoyaltyItem(
    $filter: ModelSubscriptionLoyaltyItemFilterInput
  ) {
    onDeleteLoyaltyItem(filter: $filter) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOTP = /* GraphQL */ `
  subscription OnCreateOTP($filter: ModelSubscriptionOTPFilterInput) {
    onCreateOTP(filter: $filter) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOTP = /* GraphQL */ `
  subscription OnUpdateOTP($filter: ModelSubscriptionOTPFilterInput) {
    onUpdateOTP(filter: $filter) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOTP = /* GraphQL */ `
  subscription OnDeleteOTP($filter: ModelSubscriptionOTPFilterInput) {
    onDeleteOTP(filter: $filter) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

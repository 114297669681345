import {
  AuthModeStrategyType,
  DataStore,
  Hub,
  syncExpression,
} from "aws-amplify";
import { LOCAL_STORAGE } from "../constants/enums";
import {
  Account,
  Concept,
  Feature,
  Timeline,
  Transaction,
  User,
  UserConcepts
} from "../models";

export const disableSync = (bookingID: string) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 200,
  syncExpressions: [
    // Never sync Account data
    syncExpression(Account, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Feature data
    syncExpression(Feature, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Concept data
    syncExpression(Concept, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync UserConcepts data
    syncExpression(UserConcepts, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Transaction data
    syncExpression(Transaction, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncGuestsListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    console.warn({ event });

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "false");

      cb();
    }
  });
};

export const syncGuestsConfig = () => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 100,
  syncExpressions: [
    // Sync User data
    syncExpression(User, () => {
      return (model) => model.id("ne", "NEVER_MATCH_ME");
    }),
    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncSearchGuestListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");

      cb();
    }
  });
};

export const syncGuestSearchConfig = (
  userConcepts: UserConcepts,
  fromDate: Date,
  toDate: Date,
  searchTerm: string
) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Sync User data
    syncExpression(User, () => {
      return (model) => model.phone_number("beginsWith", searchTerm);
    }),

    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncSearchGuest = async (): Promise<void> => {
  const syncSearchGuests: string | null = localStorage.getItem(
    LOCAL_STORAGE.GUESTS_SEARCH
  );

  if (syncSearchGuests && syncSearchGuests === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncGuestStatsListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    console.warn({ event });

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");

      cb();
    }
  });
};

export const syncGuestStatsConfig = (stats: string[]) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncGuestStats = async (): Promise<void> => {
  const syncGuestStats: string | null = localStorage.getItem(
    LOCAL_STORAGE.SYNC_GUEST_STATS
  );

  if (syncGuestStats && syncGuestStats === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { createZone, deleteZone, updateZone } from "../graphql/mutations";
import { getZone, listZones } from "../graphql/queries";
import { onCreateZone } from "../graphql/subscriptions";
import { removeTypenameField } from "../helpers/utils";
import { Zone } from "../models";
import {
  ListingVariables,
  Option,
  ZoneBulkTrashVariables,
  ZoneGetVariables,
  ZoneUpdateVariables,
} from "../models/app";
import { HeadCell } from "../models/dataTable";
import { CreateZoneInput, UpdateZoneInput } from "../models/GQL_API";
import {
  setListing,
  setListingAll,
  setNextToken,
  setSelected,
  setUpdatedZone,
} from "../store/ducks/zone";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const { showError, showConfirm } = useApp();
  const dispatch = useDispatch();

  const session = useSelector((state: any) => state.app.session);

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const zonesListing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );

  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit, moveForward, conceptID } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        conceptID: { eq: conceptID },
      };

      if (searchText.length > 0) {
        filter.location = { contains: searchText.toLowerCase() };
      }
      const zonesList: any = await API.graphql<Zone>({
        query: listZones,
        variables: { filter, limit: 100000, nextToken, conceptID },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      // console.log({ zonesList });

      const currentNextToken = zonesList.data.listZones.nextToken;
      const responseListing = zonesList.data.listZones.items;

      let listing = [...zonesListing, ...responseListing];

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    const { searchText, limit } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.location = { contains: searchText.toLowerCase() };
      }

      let listing = [];
      let ZoneList: any = null;
      try {
        ZoneList = await API.graphql<Zone>({
          query: listZones,
          variables: { filter, limit: 100000, nextToken: nextToken },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      } catch (err: Error | any) {
        ZoneList = await API.graphql<Zone>({
          query: listZones,
          variables: { filter, limit: 100000, nextToken: nextToken },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      listing = ZoneList.data.listZones.items;

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: ZoneGetVariables) {
    const { id, listing } = params;

    try {
      let single: Zone | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Zone>({
          query: getZone,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getZone;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: any) {
    try {
      const { conceptID, location, limit } = params;
      const filter: any = {
        conceptID: { eq: conceptID },
        deleted: { eq: "0" },
      };
      if (location) {
        filter.location = { eq: location };
      }
      const listing: any = await API.graphql<GraphQLQuery<Zone>>({
        query: listZones,
        variables: { filter, limit: 100000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const results = listing?.data?.listZones?.items ?? [];
      // Sort the results by the 'location' field in ascending order
      const sortedListing = results.sort((a: Zone, b: Zone) => {
        return a.location.localeCompare(b.location);
      });

      return sortedListing;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: any, isAuth = true) {
    try {
      const { userID, userName, data } = params;
      // console.log({ polygon: data.polygon });
      // if (!data.polygon) throw new Error("You must select the zone location");
      const createInput: CreateZoneInput = {
        conceptID: data.conceptID,
        location: data.location.trim().toLowerCase(),
        polygon: data.polygon,
        deliveryFee: data.deliveryFee,
        concepts: removeTypenameField(data.concepts ?? []),
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      const listing: any = await API.graphql<GraphQLQuery<Zone>>({
        query: createZone,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.createZone;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ZoneUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateZoneInput = {
        id: original.id,
        conceptID: data.conceptID ? data.conceptID : original!.conceptID,
        location: data.location
          ? data.location.trim().toLowerCase()
          : original!.location.trim().toLowerCase(),
        deliveryFee: data.deliveryFee
          ? data.deliveryFee
          : original!.deliveryFee,
        polygon: data.polygon ? data.polygon : original!.polygon,
        // createdAt: data.createdAt ? data.createdAt : original!.createdAt,
        concepts: removeTypenameField(
          data.concepts ? data.concepts : original.concepts ?? []
        ),
        createdByID: data.createdByID
          ? data.createdByID
          : original!.createdByID,
        createdByName: data.createdByName
          ? data.createdByName
          : original!.createdByName,
        _version: original._version,
      };

      const zone: any = await API.graphql<Zone>({
        query: updateZone,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      return zone.data.updateZone;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ZoneGetVariables) {
    try {
      const { id, listing } = params;

      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateZoneInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<Zone>({
          query: updateZone,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id))
        );
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ZoneBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(
      setListing(listing.filter((resource: any) => !ids.has(resource.id)))
    );

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ZoneGetVariables) {
    const { id, listing } = params;
    try {
      await API.graphql<Zone>({
        query: deleteZone,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  function options(listing: Zone[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.location, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "location",
      numeric: false,
      disablePadding: false,
      label: "location",
    },
    {
      id: "deliveryFee",
      numeric: false,
      disablePadding: false,
      label: "delivery fee",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["location", "deliveryFee"];

  const api: any = {};

  api[`${listingName}Model`] = Zone as any;
  api[`${listingName}CreateSubscription`] = onCreateZone;

  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Zone[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeListingAll`] = (listing: Zone[]) =>
    dispatch(setListingAll(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeUpdated`] = (zone: any) =>
    dispatch(setUpdatedZone(zone));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = zonesListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;

import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, input, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { getUserConcepts, listUserConcepts } from "../graphql/queries";
import { EagerUserConcepts, UserConcepts } from "../models";
import {
  CreateVariables,
  GetVariables,
  Option,
  UserConceptsBulkTrashVariables,
  UserConceptsGetVariables,
  UserConceptsListingVariables,
  UserConceptsUpdateVariables,
} from "../models/app";
import { HeadCell } from "../models/dataTable";
import { CreateUserConceptsInput } from "../models/GQL_API";
import { setListing, setSelected } from "../store/ducks/userConcepts";
import useApp from "./useApp";
import { createUserConcepts, updateUserConcepts } from "../graphql/mutations";
import { UpdateUserConceptsInput } from "../API";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: UserConceptsListingVariables) {
    const { searchText, startIndex, limit, userID } = params;

    try {
      const listing = await DataStore.query(
        UserConcepts as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (userID) model.createdByID("eq", userID);

          if (searchText.length > 0)
            model.defaultConcept("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchOnline(params: UserConceptsListingVariables) {
    const { searchText, startIndex, limit, userID } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (userID) {
        filter.createdByID = { eq: userID };
      }

      if (searchText.length > 0) {
        filter.defaultConcept = { contains: searchText.toLowerCase() };
      }

      let allItems: any[] = [];
      let nextToken: string | null = null;

      do {
        const dataList: any = await API.graphql({
          query: listUserConcepts,
          variables: {
            filter,
            nextToken: nextToken,
            limit: 10000,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const currentNextToken = dataList.data.listUserConcepts.nextToken;
        const responseListing = dataList.data.listUserConcepts.items;

        allItems = [...allItems, ...responseListing];
        nextToken = currentNextToken;
      } while (nextToken);

      return allItems;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: UserConcepts | undefined = await DataStore.query(
        UserConcepts as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: GetVariables) {
    const { id } = params;

    try {
      const res: any = await API.graphql<UserConcepts>({
        query: getUserConcepts,
        variables: { id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const single = res.data.getUserConcepts;

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    const listingParams: UserConceptsListingVariables = {
      startIndex: 0,
      limit: 1000,
      searchText: "",
      userID: userID,
    };

    const existed = await fetch(listingParams);

    if (existed!.length > 0 && existed) {
      const params: UserConceptsUpdateVariables = {
        id: existed[0].id,
        data: {
          concepts: data.concepts,
          conceptsRoles: data.conceptsRoles,
          parentConcepts: data.parentConcepts,
        },
      };
      await updateOnline(params);
      return;
    }

    try {
      const createInput: CreateUserConceptsInput = {
        defaultConcept: data.defaultConcept ? data.defaultConcept : "",
        concepts: data.concepts ? data.concepts : [],
        parentConcepts: data.parentConcepts ? data.parentConcepts : [],
        conceptsRoles: data.conceptsRoles ? data.conceptsRoles : [],
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new UserConcepts(createInput as any));

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UserConceptsUpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      console.log({ data });

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.defaultConcept = data.defaultConcept
            ? data.defaultConcept
            : original!.defaultConcept;
          updated.concepts = data.concepts ? data.concepts : original!.concepts;
          updated.parentConcepts = data.parentConcepts
            ? data.parentConcepts
            : original!.parentConcepts;
          updated.conceptsRoles = data.conceptsRoles
            ? data.conceptsRoles
            : original!.conceptsRoles;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function createOnline(params: CreateVariables) {
    const { userID, userName, data } = params;

    const listingParams: UserConceptsListingVariables = {
      startIndex: 0,
      limit: 1000,
      searchText: "",
      userID: userID,
    };

    const existed = await fetchOnline(listingParams);

    if (existed!.length > 0 && existed) {
      const params: UserConceptsUpdateVariables = {
        id: existed[0].id,
        data: {
          concepts: data.concepts,
          conceptsRoles: data.conceptsRoles,
          parentConcepts: data.parentConcepts,
        },
      };
      await updateOnline(params);
      return;
    }

    try {
      const createInput: CreateUserConceptsInput = {
        defaultConcept: data.defaultConcept ? data.defaultConcept : "",
        concepts: data.concepts ? data.concepts : [],
        parentConcepts: data.parentConcepts ? data.parentConcepts : [],
        conceptsRoles: data.conceptsRoles ? data.conceptsRoles : [],
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const res: any = await API.graphql({
        query: createUserConcepts,
        variables: {
          input: createInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const model = res.data.createUserConcepts;

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: UserConceptsUpdateVariables) {
    const { id, data } = params;
    console.log({ data });
    try {
      const original = await getOnline({ id });

      const updateInput: UpdateUserConceptsInput = {
        id: original.id,
        defaultConcept: data.defaultConcept
          ? data.defaultConcept
          : original!.defaultConcept,
        concepts: data.concepts ? data.concepts : original!.concepts,
        parentConcepts: data.parentConcepts
          ? data.parentConcepts
          : original!.parentConcepts,
        conceptsRoles: data.conceptsRoles
          ? data.conceptsRoles
          : original!.conceptsRoles,
        _version: original._version,
      };

      const model: any = await API.graphql({
        query: updateUserConcepts,
        variables: {
          input: updateInput,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: UserConceptsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: UserConceptsGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: UserConcepts[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.id, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = UserConcepts as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetchOnline;
  api[`${listingName}Get`] = getOnline;
  api[`${listingName}Create`] = createOnline;
  api[`${listingName}Update`] = updateOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: UserConcepts[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (selected: EagerUserConcepts | null) =>
    dispatch(setSelected(selected));

  return api;
};

export default useResource;

import { useState } from "react";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";

import FeaturedImage from "../FeaturedImage";

interface Props {
  name: string;
  label: string;
  value: any;
  setValue: any;
  register: any;
  resource?:any;
  slug?:any;
  setMultiLanguageValue?: React.Dispatch<any>
}

const ImageField: React.FC<Props> = ({
  name,
  label,
  value,
  setValue,
  register,
  resource,
  slug,
  setMultiLanguageValue
}) => {
  const [state, setState] = useState(value);
  const { loading, changeLoading } = useLoading();

  return loading ? (
    <ContentLoader />
  ) : (
    <FeaturedImage
      value={value}
      label={label}
      attachment={state}
      resource={resource}
      slug={slug}
      changeAttachment={(attachment: any) => {
        setValue(name, attachment);
        setState(attachment);
        if (setMultiLanguageValue) setMultiLanguageValue(attachment)
      }}
      isMultiLanguage={!!setMultiLanguageValue}
    />
  );
};

export default ImageField;

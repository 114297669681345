import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { useSelector } from "react-redux";
import { Pages } from "../../../constants/enums";
import DropZone from "../../DropZone/DropZone";
import ButtonLoader from "../ButtonLoader";
import MediaFiles from "../MediaFiles";
import Tabs from "../Tabs";

interface Props {
  title: string;
  attachment: any;
  closeModal: any;
  changeAttachment?: any;
  changePdf?: any;
  resource?: any;
  slug?: any;
}

const ImagePicker: React.FC<Props> = ({
  title,
  attachment,
  closeModal,
  changeAttachment,
  changePdf,
  resource,
  slug,
}) => {
  const [acceptedImageFiles, setAcceptedImageFiles] = useState<File[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const mediaLibraryListing = useSelector(
    (state: any) => state.mediaLibrary.listing
  );
  const mediaLibrarySelected = useSelector(
    (state: any) => state.mediaLibrary.selected
  );
  const update = async () => {
    setIsUpdating(true);
    if (!mediaLibrarySelected || mediaLibrarySelected.size === 0) {
      const selectedFile = acceptedImageFiles[0];
      changeAttachment(selectedFile.name);
    } else {
      for (let mediaId of mediaLibrarySelected) {
        const mediaFile: any = mediaLibraryListing.find(
          (file: any) => file.id === mediaId
        );
        if (slug === Pages.CONCEPTS) {
          changeAttachment([...attachment, mediaFile.fileUrl]);
        } else {
          changeAttachment(mediaFile.fileUrl);
        }
      }
    }
    setIsUpdating(false);
    closeModal();
  };

  const create = async () => {
    setIsUpdating(true);

    for (let mediaId of mediaLibrarySelected) {
      const mediaFile: any = mediaLibraryListing.find(
        (file: any) => file.id === mediaId
      );
       if (slug === Pages.CONCEPTS) {
        changeAttachment([...attachment, mediaFile.fileUrl]);
      } else {
        changeAttachment(mediaFile.fileUrl);
      }
    }

    setIsUpdating(false);
    closeModal();
  };

  const handleAcceptedImageFiles = (files: any) => {
    setAcceptedImageFiles(files);
  };

  return (
    <Box
      sx={{
        width: "96%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={closeModal}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box
          sx={{ height: "calc(94vh - 120px)" }}
          display={"flex"}
          flexDirection={"row"}
        >
          <Tabs
            tabs={["Media Library"]}
            panels={[<MediaFiles title={title} multiple={false} />]}
            activeTab={0}
          />
          <Box width={"30%"}>
            <Tabs
              tabs={["Upload From Your Device"]}
              panels={[
                <DropZone
                  field={"image"}
                  onAcceptedFiles={handleAcceptedImageFiles}
                />,
              ]}
              activeTab={0}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={attachment ? update : create}
            sx={{ textTransform: "capitalize" }}
            disabled={
              (mediaLibrarySelected.size === 0 &&
                !acceptedImageFiles[0]?.name) ||
              isUpdating
            }
            endIcon={isUpdating && <ButtonLoader size={20} />}
          >
            {`Set ${title}`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImagePicker;

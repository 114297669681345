import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeSentence } from "../../../helpers/utils";
import useApp from "../../../hooks/useApp";
import useLoading from "../../../hooks/useLoading";
import useUpdating from "../../../hooks/useUpdating";
import useZone from "../../../hooks/useZone";
import { Concept, Zone, ZoneConcept } from "../../../models";
import { ZoneGetVariables, ZoneUpdateVariables } from "../../../models/app";
import ButtonLoader from "../ButtonLoader";
import ContentLoader from "../ContentLoader";

interface Props {
  zoneId: string;
  conceptId: string;
}

const ZoneConceptsModal: React.FC<Props> = ({ zoneId, conceptId }) => {
  const session = useSelector((state: any) => state.app.session);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);

  const conceptsListing = useSelector((state: any) => state.concepts.listing);

  const { loading, changeLoading } = useLoading();
  const [selectedConcept, setSelectedConcept] = useState<Concept>();
  const [deliveryFee, setDeliveryFee] = useState(0);
  const { hideDeletePopup, showError } = useApp();
  const { zonesUpdate, zonesGet, zonesChangeUpdated } = useZone(
    "zones",
    "zone"
  );
  const { updating, changeUpdating } = useUpdating();
  const [zone, setZone] = useState<Zone>();
  const [zoneConcepts, setZoneConcepts] = useState<ZoneConcept[]>([]);

  const fetchData = async (zoneId: string) => {
    try {
      changeLoading(true);
      const getZoneParams: ZoneGetVariables = {
        id: zoneId,
        listing: [],
      };
      const selectedZone = await zonesGet(getZoneParams);
      const selectedZoneConcepts: ZoneConcept[] = [
        ...(selectedZone?.concepts ?? []),
      ].filter(
        (zoneConcept): zoneConcept is ZoneConcept => zoneConcept !== null
      );
      const existingZoneConcept = selectedZoneConcepts.find(
        (zoneConcept: ZoneConcept) => zoneConcept.id === conceptId
      );

      const conceptSelectedId = existingZoneConcept?.id;
      const conceptSelected = conceptsListing?.find(
        (concept: Concept) => concept?.id === conceptSelectedId
      );

      setZone(selectedZone);
      setZoneConcepts(selectedZoneConcepts);
      setSelectedConcept(conceptSelected);
      setDeliveryFee(existingZoneConcept?.deliveryFee ?? 0);
      changeLoading(false);
    } catch (err: any) {
      changeLoading(false);
      console.log({ err });
      showError(err?.message);
    }
  };

  useEffect(() => {
    fetchData(zoneId);
  }, [zoneId]);

  const handleDeliveryFeeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;

    // Remove leading zeros
    const trimmedValue = inputValue.replace(/^0+/, "");

    // Convert to number and handle empty string case
    const numericValue = trimmedValue === "" ? 0 : Number(trimmedValue);

    setDeliveryFee(numericValue);
  };

  const handleConceptChange = (event: SelectChangeEvent<string>) => {
    const conceptSelectedId = event.target.value;
    const conceptSelected = conceptsListing?.find(
      (concept: Concept) => concept?.id === conceptSelectedId
    );
    setSelectedConcept(conceptSelected);
  };

  const validateZoneConcept = (
    selectedConcept: Concept | undefined,
    deliveryFee: number
  ) => {
    if (!selectedConcept) {
      throw new Error("Please select a concept.");
    }
    if (!deliveryFee && deliveryFee !== 0) {
      throw new Error("Please add a delivery fee.");
    }
  };

  const handleSave = async () => {
    try {
      changeUpdating(true);

      validateZoneConcept(selectedConcept, deliveryFee);

      const selectedZoneConcepts = [...(zone?.concepts ?? [])].filter(
        (zoneConcept): zoneConcept is ZoneConcept => zoneConcept !== null
      );
      const existingZoneConcept = selectedZoneConcepts.find(
        (zoneConcept: ZoneConcept) => zoneConcept.id === conceptId
      );

      if (existingZoneConcept) {
        const updatedZoneConcept = { ...existingZoneConcept };
        updatedZoneConcept.deliveryFee = deliveryFee;
        const updatedZoneConceptIdx = selectedZoneConcepts.findIndex(
          (zoneConcept: ZoneConcept) => zoneConcept?.id === conceptId
        );
        selectedZoneConcepts[updatedZoneConceptIdx] = updatedZoneConcept;
      } else {
        const newZoneConcept: ZoneConcept = {
          id: selectedConcept?.id ?? "",
          deliveryFee: deliveryFee ?? 0,
        };
        selectedZoneConcepts.push(newZoneConcept);
      }

      const updateZoneParams: ZoneUpdateVariables = {
        id: zoneId,
        listing: [],
        data: {
          concepts: selectedZoneConcepts,
        },
      };
      const updatedZone = await zonesUpdate(updateZoneParams);
      zonesChangeUpdated(updatedZone);
      changeUpdating(false);
      hideDeletePopup();
    } catch (err: any) {
      console.log({ err });
      changeUpdating(false);
      showError(err?.message);
    }
  };

  if (loading) return <ContentLoader />;

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 892,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {conceptId.length === 0 ? "Add New Concept" : "Edit Concept"}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ px: 2, pt: 0, pb: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={"span"}>Delivery Fee</Typography>
          </Box>
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "50%",
                left: 14,
                transform: "translateY(-50%)",
                color: "grey.600",
              }}
            >
              EGP
            </Typography>
            <TextField
              // placeholder={"0"}
              fullWidth
              type={"number"}
              size="small"
              sx={{ "& .MuiOutlinedInput-input": { pl: 7 } }}
              inputProps={{ min: 0, inputMode: "numeric" }}
              value={Number(deliveryFee).toString()}
              onChange={(event) => handleDeliveryFeeChange(event)}
              onWheel={(e) => {
                //@ts-ignore
                e.target.blur();
              }}
            />
          </Box>
        </Box>

        <Box sx={{ px: 2, pt: 0, pb: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={"span"}>Assigned Concepts</Typography>
          </Box>
          <Select
            fullWidth
            size="small"
            value={selectedConcept?.id ?? ""}
            onChange={handleConceptChange}
            disabled={selectedConcept?.id && conceptId ? true : false}
          >
            {conceptsListing.map((concept: Concept) => (
              <MenuItem
                key={concept.id}
                value={concept.id}
                disabled={
                  zoneConcepts?.find(
                    (zoneConcept: ZoneConcept) =>
                      zoneConcept?.id === concept?.id
                  )?.id
                    ? true
                    : false
                }
              >
                {capitalizeSentence(concept.name ?? "")}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: "none" }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {conceptId.length === 0 ? "Add New Concept" : "Update Concept"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ZoneConceptsModal;

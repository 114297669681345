// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, Review, Account, Feature, Media, Concept, CategoryTag, Tender, OrderType, ParentConcept, Timeline, Call, AdminRole, AdminGroup, UserConcepts, Transaction, PaymentStat, Category, MenuItem, Price, ChoiceGroups, Choice, Cart, AdminStatus, OnlineOrder, OrderStatus, DiscountCoupon, Zone, UserIP, MainCategory, SubCategory, Channel, Promocode, PromocodeDashboard, LoyaltyItem, OTP, PromocodeItem, ItemReview, ModelUserConnection, ChoiceGroupRules, CartItem, ZoneConcept } = initSchema(schema);

export {
  User,
  Review,
  Account,
  Feature,
  Media,
  Concept,
  CategoryTag,
  Tender,
  OrderType,
  ParentConcept,
  Timeline,
  Call,
  AdminRole,
  AdminGroup,
  UserConcepts,
  Transaction,
  PaymentStat,
  Category,
  MenuItem,
  Price,
  ChoiceGroups,
  Choice,
  Cart,
  AdminStatus,
  OnlineOrder,
  OrderStatus,
  DiscountCoupon,
  Zone,
  UserIP,
  MainCategory,
  SubCategory,
  Channel,
  Promocode,
  PromocodeDashboard,
  LoyaltyItem,
  OTP,
  PromocodeItem,
  ItemReview,
  ModelUserConnection,
  ChoiceGroupRules,
  CartItem,
  ZoneConcept
};
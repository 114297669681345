// actions
const SET = "categoryTags/SET";
const SET_SEARCH_TEXT = "categoryTags/SET_SEARCH_TEXT";
const SET_SELECTED = "categoryTags/SET_SELECTED";

const SET_FILTERS = "categoryTags/SET_FILTERS";
const SET_SELECTED_FILTERS = "categoryTags/SET_SELECTED_FILTERS";
const SET_NEXT_TOKEN = "concepts/SET_NEXT_TOKEN";
const SET_ALL_LISTING = "categoryTags/SET_ALL_LISTING";

const DEFAULT_STATE = {
  listing: [],
  allListing: [],
  filters: [],
  selectedFilters: [],
  searchText: "",
  selected: "",
  nextToken: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_ALL_LISTING:
      return Object.assign({}, state, { allListing: action.allListing });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setAllListing = (allListing: any[]) => ({
  allListing,
  type: SET_ALL_LISTING,
});

import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ImagePicker from "../ImagePicker";
import { openModal, closeModal } from "../../../store/ducks/app";

import { commonWhite } from "../../../constants/colors";
import ImageContainer from "../ImageContainer";
import useMedia from "../../../hooks/useMedia";
import React, { useState } from "react";
import useApp from "../../../hooks/useApp";
import { Pages } from "../../../constants/enums";
import { Box, Typography } from "@mui/material";

interface Props {
  label: string;
  attachment: any;
  changeAttachment: any;
  resource?: any;
  slug?: any;
  value?: any;
  isMultiLanguage?: boolean;
}

const SetImage: React.FC<Props> = ({
  label,
  attachment,
  changeAttachment,
  resource,
  slug,
  value,
  isMultiLanguage,
}) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );
  const [pdf, setPdf] = useState<any>(
    typeof value === "string" ? value?.split("-")[1] : null
  );

  const { hasPermission } = useApp();
  const { mediaLibraryChangeSelected } = useMedia("mediaLibrary", "media");

  const handleEditImage = () => {
    if (hasPermission(userPermissions, "settings")) {
      dispatch(
        openModal(
          <ImagePicker
            title={label}
            attachment={attachment}
            changeAttachment={(attachment: any) => changeAttachment(attachment)}
            changePdf={(pdf: any) => setPdf(pdf)}
            closeModal={() => {
              mediaLibraryChangeSelected(new Set());
              dispatch(closeModal());
            }}
            resource={resource}
            slug={slug}
          />
        )
      );
    }
  };

  return (
    <>
      {attachment &&
      slug !== Pages.MENU_ITEMS &&
      slug !== Pages.MAIN_CATEGORIES &&
      slug !== Pages.SUB_CATEGORIES &&
      slug !== Pages.CATEGORIES &&
      slug !== Pages.CONCEPTS ? (
        <ImageContainer
          src={attachment.fileUrl}
          thumb={attachment.fileUrl}
          alt={attachment.alternativeText}
        />
      ) : attachment && slug === Pages.CONCEPTS ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {attachment.map((image: any, index: any) => (
            <Box
              key={image + index}
              onClick={() =>
                changeAttachment(attachment.filter((img: any) => img !== image))
              }
            >
              <ImageContainer
                src={image}
                thumb={attachment}
                alt={""}
                sx={{
                  height: "75px",
                  width: "75px",
                  paddingBottom: "unset",
                  "&:hover": {
                    position: "relative",
                    "&:after": {
                      content: '"x"',
                      background: "#00000073",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "50px",
                      color: "white",
                    },
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      ) : attachment && label !== "pdf" ? (
        <ImageContainer
          src={isMultiLanguage ? value : attachment}
          thumb={isMultiLanguage ? value : attachment}
          alt={""}
        />
      ) : (
        <Typography
          id="thumbnail-box"
          sx={{
            position: "relative",
            overflow: "hidden",
            display: "block",
            border: "1px solid",
            textAlign: "center",
          }}
        >
          {pdf}
        </Typography>
      )}
      <Button
        size="small"
        fullWidth
        disableRipple
        disableFocusRipple
        disableTouchRipple
        sx={{
          textDecoration: hasPermission(userPermissions, "settings")
            ? "underline"
            : "none",
          textTransform: "capitalize",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => commonWhite(theme),
          borderTop: "none",
          p: 1,
          cursor: hasPermission(userPermissions, "settings")
            ? "pointer"
            : "initial",
          "&:hover": {
            background: "transparent",
            textDecoration: hasPermission(userPermissions, "settings")
              ? "underline"
              : "none",
          },
        }}
        onClick={handleEditImage}
      >
        {label}
      </Button>
    </>
  );
};

export default SetImage;

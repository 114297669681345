// actions
const SET_RedirectToUserOrderCallCenter = "callCenter/SET";

const DEFAULT_STATE = {
    redirectToUserOrderCallCenter: false
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET_RedirectToUserOrderCallCenter:
      return Object.assign({}, state, { redirectToUserOrderCallCenter: action.redirectToUserOrderCallCenter });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setRedirectToUserOrderCallCenter = (redirectToUserOrderCallCenter: boolean) => ({ redirectToUserOrderCallCenter, type: SET_RedirectToUserOrderCallCenter });

import { listParentConcepts } from "../graphql/queries";
import { API, DataStore } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/parentConcept";
import { ParentConcept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateParentConceptInput } from "../models/GQL_API";
import {
  ConceptUpdateVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
  ListingVariables,
} from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { onCreateParentConcept } from "../graphql/subscriptions";
import { setNextToken } from "../store/ducks/parentConcept";
import {
  ModelParentConceptFilterInput,
  UpdateParentConceptInput,
} from "../API";
import { updateParentConcept } from "../graphql/mutations";
import { LOCAL_STORAGE } from "../constants/enums";
const useParentConcept = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const parentConceptsListing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  async function fetch(params: ConceptListingVariables) {
    const { limit, searchText } = params;
    try {
      const filter: ModelParentConceptFilterInput = {
        deleted: { eq: "0" },
        name: { contains: searchText },
      };

      const dataList: any = await API.graphql({
        query: listParentConcepts,
        variables: {
          filter,
          limit,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = dataList.data.listParentConcepts.nextToken;
      const responseListing = dataList.data.listParentConcepts.items;

      let listing = [...parentConceptsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: ListingVariables) {
    try {
      const { searchText } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      let listing: any[] = [];
      let currentNextToken: any = null;
      let responseListing: any[] = [];
      do {
        const parentConceptsList: any = await API.graphql<ParentConcept>({
          query: listParentConcepts,
          variables: { filter, limit: 100000, nextToken: currentNextToken },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        currentNextToken = parentConceptsList.data.listParentConcepts.nextToken;
        responseListing = parentConceptsList.data.listParentConcepts.items;

        listing = [...listing, ...responseListing];
      } while (currentNextToken);
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchOnline() {
    try {
      const listing: any = await API.graphql({
        query: listParentConcepts,
        variables: { limit: 100 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listParentConcepts.items;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateParentConceptInput = {
        name: data.name ? data.name.toLowerCase() : "",
        type: data.type ? data.type : "",
        image: data.image ? data.image : "",
        logo: data.logo ? data.logo : "",
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      // console.log("starting");
      await DataStore.save(new ParentConcept(createInput as any));
      // console.log("finished");
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      const single: ParentConcept | undefined =
        listing.length === 0
          ? await DataStore.query(ParentConcept as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;
    // console.log({ data, vat: data.vatPercentage });

    try {
      const original: any = await get({ id, listing });
      // console.log({ data, original });
      // throw "";

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateParentConceptInput = {
        id: id,
        name: data.name ? data.name : original.name,
        type: data.type ? data.type : original.type,
        _version: original._version,
      };

      const updatedParentConcept: any = await API.graphql<ParentConcept>({
        query: updateParentConcept,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      showConfirm(`${singleName} has been updated successfully`);
      return updatedParentConcept.data.updateParentConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const { id, listing } = params;

      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateParentConceptInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<ParentConcept>({
          query: updateParentConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id))
        );
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: ParentConcept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: ParentConcept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      if (option.name) options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "type"];

  const api: any = {};

  api[`${listingName}Model`] = ParentConcept as any;
  api[`${listingName}CreateSubscription`] = onCreateParentConcept;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Delete`] = trash;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Get`] = get;
  api[`${listingName}ChangeListing`] = (listing: ParentConcept[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (parentConceptID: string) => {
    dispatch(setSelected(parentConceptID));

    localStorage.setItem(
      LOCAL_STORAGE.SELECTED_PARENT_CONCEPT,
      parentConceptID
    );
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = parentConceptsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useParentConcept;
